import { useEffect, useState } from 'react';
import { PostAuthComponentParams } from '../../types/auth';
import ValidateAccountLink from './ValidateAccountLink';

const AccountLinkConsent = ({
  user,
  onContinue,
  onCancel,
  config,
  error,
  setError,
}: PostAuthComponentParams) => {
  const [shouldAccountLink, setShouldAccountLink] = useState(false);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (
      config.enableAccountLinkingPostAuthFlow == null ||
      config.enableAccountLinkingPostAuthFlow === 'false' ||
      params.get('skipIDLinking') === 'true'
    ) {
      onContinue();
      return;
    }
    if (user?.associate_to == null) {
      onContinue();
    } else {
      setShouldAccountLink(true);
    }
  }, [onContinue, user, config]);

  return (
    <>
      {shouldAccountLink ? (
        <>
          <ValidateAccountLink
            user={user}
            onContinue={onContinue}
            onCancel={onCancel}
            config={config}
            error={error}
            setError={setError}
          />
        </>
      ) : null}
    </>
  );
};

export default AccountLinkConsent;
