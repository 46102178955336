import { AppConfig } from '../types/app';
import { isDevelopment } from '../utils/env';
import { retry } from '../utils/promise';

const CONFIG_URI = '/settings.json';

let cachedConfig: AppConfig;

const init = async (): Promise<AppConfig> => {
  try {
    if (isDevelopment) {
      const response = await retry(() => import('../devconfig/settings.json'));
      return (cachedConfig = response.default);
    } else {
      const response = await retry(() => fetch(CONFIG_URI), {
        retries: 3,
        interval: 100,
      });
      return (cachedConfig = await response.json());
    }
  } catch (err: unknown) {
    throw new Error(`Failed to fetch config: ${(err as Error).message}`);
  }
};

const get = (): AppConfig => {
  if (!cachedConfig) throw new Error('Need to run init.');
  return cachedConfig;
};

const configService = {
  init,
  get,
};

export default configService;
