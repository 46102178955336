import { TextContent } from '@amzn/awsui-components-react';
import React from 'react';
import Main from './Main';
import TopHeader from './TopHeader';
import ContainerLayout from './ContainerLayout';
import { useIntl } from 'react-intl';
import styles from './CardContainer.module.css';
import accountlinkConsent from '../i18n/accountlinkConsent.messages';
import Footer from './Footer';

export const AccountLinkContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <TopHeader />
      <Main>
        <ContainerLayout>
          <div className={styles.heading_div}>
            <TextContent>
              <h1>{formatMessage(accountlinkConsent.header)}</h1>
            </TextContent>
          </div>
          {children}
        </ContainerLayout>
      </Main>
      <Footer />
    </>
  );
};
