import { useIntl } from 'react-intl';
import errorMessages from '../i18n/error.messages';
import { rumService } from '../services/rum';
import { emitAccountLinkingRUMEvents } from '../services/emitAccountLinkingRUMEvent';
import React, { useEffect } from 'react';
import { IMPRESSIONS } from '../constants/metrics';
import metricsService from '../services/metrics';
import Main from '../components/Main';
import ContainerLayout from '../components/ContainerLayout';
import CardContainer from '../components/CardContainer';
import { SpaceBetween, Button } from '@amzn/awsui-components-react';

function AccountLinkFailed({
  error,
  authToken,
}: {
  error?: Error;
  authToken?: string;
}) {
  const { formatMessage } = useIntl();

  const handleSignIn = () => {
    const urlParams = new URLSearchParams(window.location.search);
    window.location.assign(
      `${window.location.protocol}//${
        window.location.host
      }/login?${urlParams.toString()}`
    );
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const clientId = urlParams.get('client_id') || 'NA';
    const metricsPublisher = metricsService.getPublisher('ErrorPage');
    metricsPublisher.publishCounterMonitor(IMPRESSIONS, 1);
    metricsPublisher.publishStringTruncate(`ClientId`, clientId);
    rumService.recordError('Account Link Failed');
    if (authToken) {
      emitAccountLinkingRUMEvents(authToken, 'account_link_failed');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Main>
        <ContainerLayout>
          <CardContainer
            header={formatMessage(errorMessages.failedAccountLinkHeader)}
          >
            <p data-testid="error-message">
              {formatMessage(errorMessages.failedAccountLinkInfo)}
            </p>
            <SpaceBetween size="xs">
              <div style={{ float: 'right' }}>
                <Button variant="primary" onClick={handleSignIn}>
                  {formatMessage(errorMessages.failedAccountLinkSignInButton)}
                </Button>
              </div>
            </SpaceBetween>
          </CardContainer>
        </ContainerLayout>
      </Main>
    </>
  );
}

export default AccountLinkFailed;
