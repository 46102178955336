import * as awsui from '@amzn/awsui-design-tokens/polaris';

const mainStyle: any = {
  flex: '1 0 auto',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: awsui.colorBackgroundLayoutMain,
};

const Main = ({ children }: { children: React.ReactNode }) => {
  return <main style={mainStyle}>{children}</main>;
};

export default Main;
