import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import ContainerLayout from './ContainerLayout';
import metricsService from '../services/metrics';
import HelpLink from './HelpLink';
import CardContainer from './CardContainer';
import TopHeader from './TopHeader';
import Main from './Main';
import Footer from './Footer';
import { AppConfig } from '../types/app';
import { IMPRESSIONS } from '../constants/metrics';
import errorMessages from '../i18n/error.messages';
import providersService from '../services/providers';
import { rumService } from '../services/rum';
import { IdProvider } from '../types/auth';

function SignInFailed({
  config,
  error,
  provider,
}: {
  config?: AppConfig;
  error: Error;
  provider: IdProvider | string;
}) {
  const { formatMessage } = useIntl();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const clientId = urlParams.get('client_id') || 'NA';
    const metricsPublisher = metricsService.getPublisher('SignInFailed');
    metricsPublisher.publishCounterMonitor(IMPRESSIONS, 1);
    metricsPublisher.publishCounterMonitor(`ClientId:${clientId}`, 1);
    metricsPublisher.publishStringTruncate('ErrorMessage', error.message);
    rumService.recordError(error);
    rumService.recordEvent('SignInFailedError', {
      error: error,
      ClientId: clientId,
      IdP: provider,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    // To ensure the user does not end up in a endless loop in the case sign in fails
    // we reset createdAt for last used provider which practically stops the SSO-mechanism
    // from SSO-ing in case the customer tries to sign in again.
    // This is to enable a customer to change what IDP to use in case the selected one has
    // any temporary issues.
    providersService.resetLastUsedProviderCreatedAt();
  }, []);

  const enableCookieComponent = config?.enableCookieComponent === 'true';

  return (
    <>
      <TopHeader />
      <Main>
        <ContainerLayout>
          <CardContainer
            header={formatMessage(errorMessages.failedSigninHeader)}
            data-testid="signin-failed-header"
          >
            <p>{formatMessage(errorMessages.failedSigninInfo)}</p>
          </CardContainer>
          <HelpLink />
        </ContainerLayout>
      </Main>
      <Footer enableCookieComponent={enableCookieComponent} />
    </>
  );
}

export default SignInFailed;
