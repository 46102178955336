import Auth from '@aws-amplify/auth';
import { FederatedSignInOptions } from '@aws-amplify/auth/lib-esm/types';
import { IdProvider } from '../types/auth';
import providersService from '../services/providers';
import metricsService from '../services/metrics';
import logger from '../utils/logger';
import { AppConfig } from '../types/app';
import { NONCE_ENTROPY_BYTES } from '../constants/nonce';
import { NonceGenerator } from '../contexts/NonceGeneratorContextProvider';
import { StateStorage } from '../contexts/StateStorageContextProvider';
import { CLOSE_POPUP_MS, IDP_URL_PARAM } from '../constants/auth';
import { rumService } from '../services/rum';
import { IdPs } from '../constants/providers';
import { setAmplifyConfig } from '../authConfig/amplify';

function getClientIdFromUrl(): string {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('client_id') || 'NA';
}

function publishAuthMetrics(provider: IdProvider, namespace: string) {
  const clientId = getClientIdFromUrl();
  const metricsPublisher = metricsService.getPublisher(namespace);
  metricsPublisher.publishCounterMonitor('SignIn', 1);
  metricsPublisher.publishCounterMonitor(`IdP:${provider.idp}`, 1);
  metricsPublisher.publishCounterMonitor(`ClientId:${clientId}`, 1);
  if (namespace === 'PostAuth') {
    rumService.recordEvent('idp_signIn', {
      IdP: provider,
      ClientId: clientId,
    });
  }
}

/**
 * Build a URL-safe, base64-encoded nonce.
 */
export const buildNonce = (generator: NonceGenerator): string => {
  const data = new ArrayBuffer(NONCE_ENTROPY_BYTES);
  generator.generateNonce(data);
  return btoa(
    Array.from(new Uint8Array(data))
      .map((byte) => String.fromCharCode(byte))
      .join('')
  );
};

/**
 * Start the auth flow by signing in using the Gandalf app client.
 */
export function startAuthFlow({
  provider,
  config,
  storage,
  nonceGenerator,
}: {
  provider: IdProvider;
  config: AppConfig;
  storage: StateStorage;
  nonceGenerator: NonceGenerator;
}) {
  setAmplifyConfig(config, provider);

  if (provider.idp !== IdPs.GandalfSession) {
    providersService.setLastUsedProvider(provider);
  }

  // Early exit in case post auth flows are not enabled. Go directly to LXP auth.
  if (
    config.enableEulaPostAuthFlow !== 'true' &&
    config.enableAccountLinkingPostAuthFlow !== 'true' &&
    config.enableEmailVerification !== 'true' &&
    config.enableGandalfSession !== 'true'
  ) {
    redirectToLXPAuth(provider);
    return;
  }
  const urlParams = new URLSearchParams(window.location.search);
  const clientId = urlParams.get('client_id') || 'NA';
  rumService.recordEvent('PreAuth', {
    IdP: provider,
    ClientId: clientId,
  });
  publishAuthMetrics(provider, 'PreAuth');

  const nonce = buildNonce(nonceGenerator);
  storage.setItem(nonce, JSON.stringify(provider));

  return Auth.federatedSignIn({
    provider: provider.idp,
    customState: nonce,
  } as FederatedSignInOptions).catch((error) => {
    logger.debug('Failed to do federatedSignIn.', error);
  });
}

/**
 * Redirect to sign in using the LXP app client.
 */
export function redirectToLXPAuth(provider: IdProvider) {
  publishAuthMetrics(provider, 'PostAuth');
  const urlParams = new URLSearchParams(window.location.search);
  const clientId = urlParams.get('client_id') || 'NA';
  rumService.recordEvent('PostAuth', {
    IdP: provider,
    ClientId: clientId,
  });
  window.location.assign(provider.url);
}

export function replayAuth(provider: string, parameters: URLSearchParams) {
  parameters.set(IDP_URL_PARAM, provider);
  window.open(
    `${window.location.protocol}//${
      window.location.host
    }/login?${parameters.toString()}`,
    '_self',
    'noopener,noreferrer'
  );
}

/**
 * Log out from the Gandalf SignIn client.
 * See docs: https://docs.aws.amazon.com/cognito/latest/developerguide/logout-endpoint.html
 */
export function getLogoutUrl(config: AppConfig) {
  const redirectUrl = `${window.location.origin}/login`;
  return `https://${config.gandalfDomain}/logout?client_id=${config.authClientId}&logout_uri=${redirectUrl}`;
}
/**
 * Need to sign the user out when not authorized because the session is still active.
 * Not doing this will not allow them to change the email for ex. OTP.
 * @param config
 * @param setHasLoggedOut
 */
export function handleLogoutOut(
  config: AppConfig,
  setHasLoggedOut: React.Dispatch<React.SetStateAction<boolean>>
) {
  const logoutUrl = getLogoutUrl(config);
  const windowSettings = `toolbar=no,
      location=no,
      status=no,
      menubar=no,
      scrollbars=yes,
      resizable=yes,
      width=300,
      height=300,
      noopener,
      noreferrer`;
  const windowReference = window.open(logoutUrl, '_blank', windowSettings);
  setTimeout(() => {
    if (!windowReference || windowReference.closed) return;
    setHasLoggedOut(true);
    windowReference.close();
  }, CLOSE_POPUP_MS);
}
