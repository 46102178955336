import { BrowserRouter, Switch, Route } from 'react-router-dom';

import ErrorBoundary from './components/ErrorBoundary';
import { AppURL } from './constants/urls';
import SignIn from './pages/SignIn';
import Error from './pages/Error';
import PostAccountLinkValidate from './pages/PostAccountLinkValidate';
import ESSOPage from './pages/ESSOPage';
import AuthResponse from './pages/AuthResponse';
import useHtmlLocale from './hooks/useHtmlLocale';
import { AppConfig } from './types/app';
import Help from './pages/Help';
import WithProviders from './components/WithProviders';
import { Logout } from './pages/Logout';
import EmailOTPSignIn from './pages/EmailOTPSignIn';
import AnswerChallenge from './pages/AnswerChallenge';
import AccountLinkFailed from './pages/AccountLinkFailed';
import ValidatedWrongAccount from './components/ValidatedWrongAccount';
import LogOutValidatedWrongAccount from './pages/LogOutValidatedWrongAccount';
import AccountLinkFAQ from './pages/AccountLinkFAQ';
import SignOutToAllowSignIn from './components/SignOutToAllowSignIn';
import MultipleLwaAccountLinkSupport from './components/MultipleLwaAccountLinkSupport';
import SignOutToAllowSignInFlowRedirect from './pages/SignOutToAllowSignInFlowRedirect';
import LwaWarningComponent from './components/LwaWarningComponent';
import PostOTPValidate from './pages/PostOTPValidate';
import SendVerificationEmail from './pages/SendVerificationEmail';
import { SignInVerified } from './pages/SignInVerified';

function App({ config }: { config: AppConfig }) {
  useHtmlLocale();
  return (
    <ErrorBoundary FallbackComponent={Error}>
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path={AppURL.Login}
            render={() => (
              <WithProviders config={config}>
                {({ providers }) => (
                  <SignIn config={config} providers={providers} />
                )}
              </WithProviders>
            )}
          />
          <Route
            exact
            path={AppURL.EnterpriseEmailLookup}
            render={() => (
              <WithProviders config={config}>
                {({ providers }) => (
                  <ESSOPage config={config} providers={providers} />
                )}
              </WithProviders>
            )}
          />
          <Route
            exact
            path={AppURL.SendVerificationEmail}
            render={() => <SendVerificationEmail config={config} />}
          />
          <Route
            exact
            path={AppURL.VerifyEmails}
            render={() => <PostOTPValidate config={config} />}
          />
          <Route
            exact
            path={AppURL.LoginResponse}
            render={() => <AuthResponse config={config} />}
          />
          <Route
            exact
            path={AppURL.Help}
            render={() => <Help config={config} />}
          />
          <Route
            exact
            path={AppURL.Logout}
            render={() => <Logout config={config} />}
          />
          <Route
            path={AppURL.OTPInput}
            render={() => <EmailOTPSignIn config={config} />}
          />
          <Route
            path={AppURL.OTPChallenge}
            render={() => <AnswerChallenge config={config} />}
          />
          <Route
            exact
            path={AppURL.AccountLinkValidate}
            render={() => <PostAccountLinkValidate config={config} />}
          />
          <Route
            exact
            path={AppURL.AccountLinkFailed}
            render={() => <AccountLinkFailed />}
          />
          <Route
            exact
            path={AppURL.ValidatedWrongAccount}
            render={() => <ValidatedWrongAccount config={config} />}
          />
          <Route
            exact
            path={AppURL.LogOutValidatedWrongAccount}
            render={() => <LogOutValidatedWrongAccount config={config} />}
          />
          <Route
            exact
            path={AppURL.AccountLinkFAQ}
            render={() => <AccountLinkFAQ config={config} />}
          />
          <Route
            exact
            path={AppURL.SignOutToAllowSignIn}
            render={() => <SignOutToAllowSignIn config={config} />}
          />
          <Route
            exact
            path={AppURL.MultipleLwaAccountLinkSupport}
            render={() => <MultipleLwaAccountLinkSupport config={config} />}
          />
          <Route
            exact
            path={AppURL.SignOutToAllowSignInFlowRedirect}
            render={() => <SignOutToAllowSignInFlowRedirect config={config} />}
          />
          <Route
            exact
            path={AppURL.LwaSignInWarning}
            render={() => <LwaWarningComponent config={config} />}
          />
          <Route
            exact
            path={AppURL.LoginVerified}
            render={() => <SignInVerified />}
          />
          <Route
            render={() => <Error config={config} details="Invalid path" />}
          />
        </Switch>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
