import { useEffect, useState, useRef } from 'react';
import {
  Alert,
  Button,
  Form,
  FormField,
  Header,
  Input,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';

import metricsService from '../services/metrics';
import useQueryEmailProvider from '../hooks/useQueryEmailProvider';
import { startAuthFlow } from '../services/auth';
import { IMPRESSIONS } from '../constants/metrics';
import { AppConfig } from '../types/app';
import emailLookupMessages from '../i18n/emailLookup.messages';
import { useStateStorage } from '../contexts/StateStorageContextProvider';
import { useNonceGenerator } from '../contexts/NonceGeneratorContextProvider';
import styles from '../components/ProviderButton.module.css';
import { IdProvider } from '../types/auth';
import { useAmplifyConfig } from '../hooks/useAmplifyConfig';
import signinMessages from '../i18n/signin.messages';
import { ClickDetail } from '@amzn/awsui-components-react/polaris/internal/events';

// Same as used for AWSLabsGandalfSignInUI email OTP, https://tiny.amazon.com/phl0x69x/codeamazpackAWSLblob0659src.
const emailRegExp = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

function validateEmail(email: string) {
  return emailRegExp.test(email);
}

function EnterpriseEmailLookup({
  config,
  providers,
}: {
  config: AppConfig;
  providers: IdProvider[];
}) {
  const { formatMessage } = useIntl();
  const [emailValue, setEmailValue] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const { isLoading, error, result, queryEmailDomain, resetResult } =
    useQueryEmailProvider(config, providers);
  const metricsPublisher = useRef(
    metricsService.getPublisher('EnterpriseEmailLookup')
  );
  const stateStorage = useStateStorage();
  const nonceGenerator = useNonceGenerator();
  useAmplifyConfig(config, result?.provider);
  useEffect(() => {
    metricsPublisher.current.publishCounterMonitor(IMPRESSIONS, 1);
    window.onpageshow = function (event) {
      if (event.persisted) {
        setIsLoggingIn(false);
        setEmailValue('');
      }
    };
  }, []);
  // Redirect to the provider if found.
  useEffect(() => {
    if (result?.provider) {
      metricsPublisher.current.publishCounterMonitor('LookupSuccessful', 1);
      startAuthFlow({
        provider: result.provider,
        config,
        storage: stateStorage,
        nonceGenerator,
      });
      setIsLoggingIn(true);
    }
  }, [config, result, stateStorage, nonceGenerator]);
  // Capture metric when enterprise domain was not found.
  useEffect(() => {
    if (result?.isFound === false) {
      metricsPublisher.current.publishCounterMonitor('NotFound', 1);
    }
  }, [result]);
  // Capture metric when the API request fails.
  useEffect(() => {
    if (error) {
      metricsPublisher.current.publishCounterMonitor('Error', 1);
      metricsPublisher.current.publishStringTruncate(
        'ErrorMsg',
        error.toString()
      );
      metricsPublisher.current.publishStringTruncate(
        'UserAgent',
        navigator.userAgent
      );
    }
  }, [error]);

  const handleFormSubmit = (event: CustomEvent<ClickDetail>) => {
    const isValid = validateEmail(emailValue);
    setIsValidEmail(isValid);
    if (!isValid) return;
    queryEmailDomain(emailValue);
  };

  const handleKeyDown = (e: any) => {
    if (e.detail.key === 'Enter') {
      handleFormSubmit(e);
    }
  };

  const isLoadingOrLoggingIn = isLoading || isLoggingIn;

  return (
    <>
      <div className={[styles.idpBox].join(' ')}>
        <div className={[styles.rowElements].join(' ')}>
          <div className={styles.idpHeader}>
            <Header
              id="emailLookup-providerLabel"
              data-testid="emailLookup-providerLabel"
              variant="awsui-h1-sticky"
              headingTagOverride="h2"
              description={formatMessage(signinMessages.essoSignInDescription, {
                br: <br />,
              })}
            >
              {formatMessage(signinMessages.organizationSigninButton)}
            </Header>
          </div>
        </div>

        <Form>
          <SpaceBetween direction="vertical" size="s">
            <FormField
              errorText={
                isValidEmail
                  ? ''
                  : formatMessage(emailLookupMessages.invalidEmailValidation, {
                      i: (str) => <i>{str}</i>,
                    })
              }
            >
              <div className={[styles.rowElements].join(' ')}>
                <div className={styles.essoEmailInputBox}>
                  <Input
                    id="email-input"
                    type="email"
                    value={emailValue}
                    disableBrowserAutocorrect={true}
                    placeholder={formatMessage(
                      signinMessages.essoInputPlaceHolder
                    )}
                    onChange={(event) => {
                      resetResult();
                      setIsValidEmail(true);
                      setEmailValue(event.detail.value);
                    }}
                    className={styles.mobileInput}
                    data-testid="email-input"
                    onKeyDown={handleKeyDown}
                  />
                </div>
                <div className={styles.essoSiginInButton}>
                  <Button
                    data-testid="email-submit"
                    id="signin-emailLookup"
                    loading={isLoadingOrLoggingIn}
                    onClick={(event) => {
                      handleFormSubmit(event);
                    }}
                    ariaLabel={`${formatMessage(
                      signinMessages.signinButton
                    )} ${formatMessage(
                      signinMessages.organizationSigninButton
                    )}`}
                  >
                    {formatMessage(signinMessages.signinButton)}
                  </Button>
                </div>
              </div>
            </FormField>
            <div aria-live="assertive">
              {Boolean(result && !result.isFound) && (
                <Alert type="warning" data-testid="email-alert" visible={true}>
                  {formatMessage(emailLookupMessages.organizationNotFoundInfo, {
                    i: (str) => <i>{str}</i>,
                  })}
                </Alert>
              )}
              {error && (
                <Alert type="warning" data-testid="email-errorAlert">
                  {formatMessage(emailLookupMessages.lookupRequestError)}
                </Alert>
              )}
            </div>
          </SpaceBetween>
        </Form>
      </div>
    </>
  );
}

export default EnterpriseEmailLookup;
