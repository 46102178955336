import { useHistory } from 'react-router-dom';
import { AppURL } from '../constants/urls';
import { useEffect } from 'react';
import { REQUIRE_EMAIL_VERIFICATION } from '../constants/auth';

export function SignInVerified() {
  const history = useHistory();
  useEffect(() => {
    history.replace(
      `${AppURL.Login}${window.location.search}&${REQUIRE_EMAIL_VERIFICATION}=true`
    );
  }, [history]);

  return <></>;
}
