import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { CompleteEmailVerification } from '../components/CompleteEmailVerification';
import ContainerLayout from '../components/ContainerLayout';
import Footer from '../components/Footer';
import Main from '../components/Main';
import TopHeader from '../components/TopHeader';
import {
  AuthToken,
  LAST_USED_TOKEN,
  unmarshalAuthToken,
} from '../utils/authToken';
import { AppConfig } from '../types/app';
import logger from '../utils/logger';

function PostOTPValidate({ config }: { config: AppConfig }) {
  const [otpAuthToken, setOtpAuthToken] = useState<string>();
  const [unmarshalledAuthToken, setUnmarshalledAuthToken] =
    useState<AuthToken | null>();
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const marshalledGandalfAuthToken = localStorage.getItem(LAST_USED_TOKEN);
  const state = queryParameters.get('state');
  const code = queryParameters.get('code');

  useEffect(() => {
    if (!marshalledGandalfAuthToken || !state) {
      logger.error('Missing required parameters');
      return;
    }

    // perform token exchange with the OTP IDP
    const originalAuthToken = unmarshalAuthToken(
      marshalledGandalfAuthToken,
      state
    );
    setUnmarshalledAuthToken(originalAuthToken);

    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    const urlencoded = new URLSearchParams();
    urlencoded.append('grant_type', 'authorization_code');
    urlencoded.append('code', code?.toString() ?? '');
    urlencoded.append('client_id', config.otpUserPoolClientId as string);

    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: urlencoded,
    };
    fetch(`${config.otpOidcApiUrl}/token`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setOtpAuthToken(result?.id_token);
      })
      .catch((error) => logger.error(error));
  }, [config, code, marshalledGandalfAuthToken, state]);

  return (
    <>
      <TopHeader />
      <Main>
        <ContainerLayout>
          {unmarshalledAuthToken && otpAuthToken ? (
            <CompleteEmailVerification
              config={config}
              authToken={unmarshalledAuthToken}
              otpAuthToken={otpAuthToken}
            />
          ) : (
            <></>
          )}
        </ContainerLayout>
      </Main>
      <Footer />
    </>
  );
}

export default PostOTPValidate;
