// Utils for working with the RUM client
import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import { RUM_SESSION, RUM_USER } from '../constants/cookies';
import { AppConfig } from '../types/app';
import logger from '../utils/logger';
import { getShortbread } from './cookies';

let rumClient: AwsRum;
let initConfig: AppConfig;

export const areRUMCookiesAllowed = () => {
  const sb = getShortbread();
  return sb.hasConsent(RUM_USER) && sb.hasConsent(RUM_SESSION);
};

export const makeRumClient = (config: AppConfig, useCookies: boolean) => {
  if (!config?.rumConfig) {
    return;
  }
  const endpoint = 'https://dataplane.rum.us-east-1.amazonaws.com';
  const { appId, guestRoleArn, identityPoolId, proxyEndpoint } =
    config.rumConfig;
  logger.debug(`RUM proxyEndpoint: ${proxyEndpoint}`+
    ` -> ${proxyEndpoint ? true : false}`);
  const rumConfig: AwsRumConfig = {
    identityPoolId: proxyEndpoint ? undefined : identityPoolId,
    guestRoleArn: proxyEndpoint ? undefined : guestRoleArn,
    allowCookies: useCookies,
    enableXRay: true,
    sessionSampleRate: 1,
    sessionEventLimit: 0,
    endpoint: proxyEndpoint ? proxyEndpoint : endpoint,
    signing: proxyEndpoint ? false : true,
    telemetries: [
      'performance',
      'http',
      'errors',
      ['interaction', { events: [{ event: 'click', element: document }] }],
    ],
  };
  rumClient = new AwsRum(appId, '1.0.5', 'us-east-1', rumConfig);
  return rumClient;
};

export const rumService = {
  init(appConfig: AppConfig) {
    initConfig = appConfig;
    if (Boolean(appConfig.enableCookieComponent === 'false')) {
      makeRumClient(initConfig, false);
    }
  },

  recordError(e: Error | string) {
    logger.debug(`RUM recordError: ${e}`);
    if (rumClient) rumClient.recordError(e);
  },

  recordEvent(eventType: string, eventObject: object) {
    logger.debug(`RUM recordEvent: ${eventType}`);
    if (rumClient) rumClient.recordEvent(eventType, eventObject);
  },

  updateCookieConsent() {
    makeRumClient(initConfig, areRUMCookiesAllowed());
  },
};
