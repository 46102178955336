import { useEffect } from 'react';
import { Alert } from '@amzn/awsui-components-react/polaris';
import { useIntl } from 'react-intl';

import ContainerLayout from '../components/ContainerLayout';
import metricsService from '../services/metrics';
import HelpLink from '../components/HelpLink';
import CardContainer from '../components/CardContainer';
import TopHeader from '../components/TopHeader';
import Main from '../components/Main';
import Footer from '../components/Footer';
import { AppConfig } from '../types/app';
import { IMPRESSIONS } from '../constants/metrics';
import errorMessages from '../i18n/error.messages';
import { rumService } from '../services/rum';

function Error({ config, details }: { config?: AppConfig; details?: string }) {
  const { formatMessage } = useIntl();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const clientId = urlParams.get('client_id') || 'NA';
    const metricsPublisher = metricsService.getPublisher('ErrorPage');
    metricsPublisher.publishCounterMonitor(IMPRESSIONS, 1);
    metricsPublisher.publishStringTruncate(`ClientId`, clientId);
    if (details) metricsPublisher.publishStringTruncate('Details', details);
    rumService.recordError(urlParams.get('error') || 'no error code');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const enableCookieComponent = Boolean(
    config?.enableCookieComponent === 'true'
  );

  return (
    <>
      <TopHeader />
      <Main>
        <ContainerLayout>
          <CardContainer
            header={formatMessage(errorMessages.errorHeader)}
            description={formatMessage(errorMessages.errorSubHeaderDescription)}
          >
            <p data-testid="error-message">
              {formatMessage(errorMessages.generalErrorMessage)}
            </p>
            {details && (
              <Alert type="warning" data-testid="error-details" visible={true}>
                {details}
              </Alert>
            )}
          </CardContainer>
          <HelpLink />
        </ContainerLayout>
      </Main>
      <Footer enableCookieComponent={enableCookieComponent} />
    </>
  );
}

export default Error;
