import { Button } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';
import accountlinkConsentMessages from '../i18n/accountlinkConsent.messages';
import { AuthToken } from '../utils/authToken';
import { emitAccountLinkingRUMEvents } from '../services/emitAccountLinkingRUMEvent';

export const ICANT_BUTTON_DATA_ID = 'al_icant';

export const AccountLinkICantButton = ({
  authToken,
  onCantClicked,
}: {
  authToken: AuthToken;
  onCantClicked: () => void;
}): JSX.Element => {
  const { formatMessage } = useIntl();

  const initiateICantFlow = () => {
    emitAccountLinkingRUMEvents(
      authToken.jwtToken,
      'confirm_existing_profile_i_cant_button'
    );

    onCantClicked();
  };

  return (
    <>
      <Button
        onClick={(e) => {
          e.preventDefault();
          initiateICantFlow();
        }}
        data-testid={ICANT_BUTTON_DATA_ID}
      >
        {formatMessage(
          accountlinkConsentMessages.cantConfirmExistingProfileOwnershipButton
        )}
      </Button>
    </>
  );
};
