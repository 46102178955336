import { useCallback } from 'react';
import { AuthToken } from '../utils/authToken';
import {
  GandalfLoginAssociationClient,
  PutAssociationWithTokenValidationCommand,
  InvalidValidationTokenException,
} from '@amzn/gandalfloginassociation-client';
import { AppConfig } from '../types/app';
import { AppURL } from '../constants/urls';
import { useHistory } from 'react-router-dom';
export type LinkAccountsFunction = (
  gandalfToken: string,
  config: AppConfig,
  validationToken?: string,
  history?: any
) => Promise<void>;

export const linkAccounts: LinkAccountsFunction = async (
  gandalfToken,
  config,
  validationToken,
  history
) => {
  const linkingClient = new GandalfLoginAssociationClient({
    endpoint: config.accountLinkingEndpoint,
    region: config.region,
  });
  linkingClient.middlewareStack.removeByTag('SIGNATURE');

  await linkingClient.send(
    new PutAssociationWithTokenValidationCommand({
      gandalfToken,
      gandalfValidationToken:
        validationToken === '' ? undefined : validationToken,
    })
  );
};

export const useLinkndRedirect = ({
  at2Token,
  validationToken,
  linkAccountsFunction = linkAccounts,
  config,
  onLinkComplete = () => {},
  onAccountLinkFail = (e: any) => {},
}: {
  at2Token: AuthToken | null;
  validationToken?: string;
  linkAccountsFunction?: LinkAccountsFunction;
  config: AppConfig;
  onLinkComplete?: () => void;
  onAccountLinkFail?: (e: any) => void;
}): (() => void) => {
  const reactHistory = useHistory();
  return useCallback(() => {
    if (!at2Token) {
      throw new Error('Account link failed with no at2 token');
    }

    return linkAccountsFunction(
      at2Token.jwtToken,
      config,
      validationToken,
      reactHistory
    )
      .then(() => {
        onLinkComplete();
        window.open(
          `${window.location.protocol}//${window.location.host}/login?${at2Token.originalRequestURL}`,
          '_self',
          'noopener,noreferrer'
        );
      })
      .catch((e) => {
        if (e instanceof InvalidValidationTokenException) {
          reactHistory.push({
            pathname: AppURL.ValidatedWrongAccount,
            search: at2Token.originalRequestURL,
            state: {
              originalAuthToken: at2Token.jwtToken,
              validatedAuthToken: validationToken,
            },
          });
        } else {
          // eslint-disable-next-line
          reactHistory.replace(
            window.location.pathname + '?' + at2Token.originalRequestURL
          );
          onAccountLinkFail(e);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [at2Token, validationToken, linkAccountsFunction, config, onLinkComplete]);
};
