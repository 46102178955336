import { useState } from 'react';
import { EMAIL_LOOKUP_PATH } from '../constants/api';
import providersService from '../services/providers';

import { ENTERPRISE_LOOKUP_PATH } from '../constants/enterprise';
import { AppConfig } from '../types/app';
import { IdProvider } from '../types/auth';
import { request } from '../utils/apiHelper';
import logger from '../utils/logger';
import { retry } from '../utils/promise';
import useProviders from './useProviders';

type QueryResult = {
  isFound: boolean;
  provider?: IdProvider;
};

/**
 * Lookup locally. Each email domain has it's own file containing the connected IDP.
 *
 * For example.
 *   the file: "/ENTERPRISE_LOOKUP_PATH/amazon.com"
 *   contains: "{ 'idp': 'AmazonFederate'}".
 *
 * @deprecated This is getting replaced with the Gandalf IDP API.
 */
async function queryLocally(
  emailDomain: string
): Promise<GetEmailDomainIdpOutput | undefined> {
  const response = await retry(() =>
    fetch(`${ENTERPRISE_LOOKUP_PATH}/${emailDomain}`)
  );

  let result;
  try {
    result = await response.json();
  } catch (error) {
    // This means that the lookup resulted in a non-json response where we
    // would have expected json. This is because 404 requests will be
    // routed to index.html per default by the SPA redirect.
  }

  return result?.idp ? { idp: result.idp } : undefined;
}

type GetEmailDomainIdpOutput = {
  idp: string;
  authEndpoint?: string;
  userPoolId?: string;
  clientId?: string;
};
function getClientIdFromUrl(): string {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('client_id') || 'NA';
}

/**
 * Lookup the email domain using the Gandalf IDP API.
 */
async function queryIdpApi(
  config: AppConfig,
  emailDomain: string
): Promise<GetEmailDomainIdpOutput | undefined> {
  const clientId = getClientIdFromUrl();
  const response = await request(
    `${config.gandalfIDPEndpoint}${EMAIL_LOOKUP_PATH}/${emailDomain}?clientId=${clientId}`,
    {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    { metricNs: 'GetEmailDomain', treat404AsOk: true }
  );
  if (response.status === 404) return;
  const result: GetEmailDomainIdpOutput = await response.json();
  return result;
}

/**
 * Use the email to lookup the connected IDP through the domain name.
 *
 * Requests for a domain that does not exist is treated as a
 * non-ESSO onboarded domain.
 */
async function queryEmailProvider(
  config: AppConfig,
  email: string
): Promise<GetEmailDomainIdpOutput | undefined> {
  let [, emailDomain] = email.split('@');
  if (!emailDomain) return;
  emailDomain = emailDomain.toLowerCase();

  // Check whether or not we should use the IDP API to do the email lookup.
  return config.enableEmailLookupApi === 'true'
    ? queryIdpApi(config, emailDomain)
    : queryLocally(emailDomain);
}

function useQueryEmailProvider(config: AppConfig, idpProviders: IdProvider[]) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>();
  const [result, setResult] = useState<QueryResult | undefined>();
  const { providers } = useProviders(config);

  function queryEmailDomain(email: string) {
    setIsLoading(true);
    setError(undefined);
    setResult(undefined);
    queryEmailProvider(config, email)
      .then((idpConfig) => {
        const provider = idpConfig
          ? providersService.getProvider(
              idpConfig.idp,
              providers ?? idpProviders
            )
          : undefined;
        if (idpConfig?.authEndpoint) {
          setResult({
            isFound: Boolean(provider),
            provider: {
              idp: idpConfig.idp,
              url: providersService.getIdpUrl(
                idpConfig.idp,
                idpConfig.authEndpoint
              ),
              userPoolId: idpConfig.userPoolId,
              clientId: idpConfig.clientId,
            },
          });
        } else {
          setResult({
            isFound: Boolean(provider),
            provider: provider,
          });
        }
      })
      .catch((error) => {
        logger.debug(error);
        setError(error);
      })
      .finally(() => setIsLoading(false));
  }

  function resetResult() {
    setResult(undefined);
  }

  return {
    isLoading,
    error,
    result,
    queryEmailDomain,
    resetResult,
  };
}

export default useQueryEmailProvider;
