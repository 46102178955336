import { useEffect, useState } from 'react';

import { Icon, Spinner } from '@amzn/awsui-components-react';

import { useEmailVerification } from '../hooks/useEmailVerification';
import { AppConfig } from '../types/app';
import { AuthToken } from '../types/auth';

export const CompleteEmailVerification = ({
  config,
  authToken,
  otpAuthToken,
}: {
  config: AppConfig;
  authToken: AuthToken;
  otpAuthToken: string;
}) => {
  const [failedVerification, setFailedVerification] = useState<boolean>(false);
  const [verificationSuccess, setVerificationSuccess] =
    useState<boolean>(false);

  const verifyEmail = useEmailVerification({
    config,
    authToken,
    otpAuthToken,
    onVerificationComplete: () => {
      setVerificationSuccess(true);
    },
    onVerificationFail: () => {
      setFailedVerification(true);
    },
  });

  useEffect(() => {
    try {
      verifyEmail();
    } catch (e) {
      setFailedVerification(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {failedVerification ? (
          <Icon name="status-negative" size="large" />
        ) : verificationSuccess ? (
          <></>
        ) : (
          <Spinner size="large" />
        )}
      </div>
    </>
  );
};
