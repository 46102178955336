import React, { useEffect } from 'react';
import TopHeader from '../components/TopHeader';
import Main from '../components/Main';
import Footer from '../components/Footer';
import { AppConfig } from '../types/app';
import ContainerLayout from './ContainerLayout';
import {
  Button,
  Container,
  Form,
  Header,
  SpaceBetween,
  TextContent,
} from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';
import styles from './CardContainer.module.css';
import lwaSunsetMessages from '../i18n/lwaSunsetPhase.messages';
import lwaSunsetPhaseMessages from '../i18n/lwaSunsetPhase.messages';
import { useStateStorage } from '../contexts/StateStorageContextProvider';
import { useNonceGenerator } from '../contexts/NonceGeneratorContextProvider';
import { startAuthFlow } from '../services/auth';
import { IdPs } from '../constants/providers';
import { IdProvider } from '../types/auth';
import providersService from '../services/providers';
import { publishAuthMetrics } from '../utils/publishLwaSunsetMetrics';
import { useAmplifyConfig } from '../hooks/useAmplifyConfig';

const LwaWarningComponent = ({
  config,
}: {
  config: AppConfig;
}): JSX.Element => {
  const enableCookieComponent = Boolean(
    config?.enableCookieComponent === 'true'
  );

  useAmplifyConfig(config);

  const lwaProvider: IdProvider = providersService.transformNameToIdProvider(
    IdPs.LoginWithAmazon,
    config.gandalfDomain
  );

  const bidProvider: IdProvider = providersService.transformNameToIdProvider(
    IdPs.AWSBuilderID,
    config.gandalfDomain
  );

  const { formatMessage, formatDate } = useIntl();

  const stateStorage = useStateStorage();

  const nonceGenerator = useNonceGenerator();

  function initiateBuilderIDAuth() {
    publishAuthMetrics(
      IdPs.LoginWithAmazon,
      'LwaPreAuthWarning',
      'ContinueWithBuilderID'
    );
    startAuthFlow({
      provider: bidProvider,
      config,
      storage: stateStorage,
      nonceGenerator,
    });
  }

  function continueCurrentAuth() {
    publishAuthMetrics(
      IdPs.LoginWithAmazon,
      'LwaPreAuthWarning',
      'ContinueWithLwa'
    );
    startAuthFlow({
      provider: lwaProvider,
      config,
      storage: stateStorage,
      nonceGenerator,
    });
  }

  useEffect(() => {
    publishAuthMetrics(
      IdPs.LoginWithAmazon,
      'LwaPreAuthWarning',
      'Page_Impression'
    );
  }, []);

  return (
    <>
      <TopHeader />
      <Main>
        <ContainerLayout>
          <Container
            header={
              <Header
                className={styles.header}
                variant="h2"
                headingTagOverride="h1"
              >
                {formatMessage(
                  lwaSunsetPhaseMessages.recommendSignInWithBuilderIdHeader
                )}
              </Header>
            }
          >
            <Form
              actions={
                <>
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      data-testid="continue"
                      onClick={(e) => {
                        e.preventDefault();
                        continueCurrentAuth();
                      }}
                      id="continue"
                    >
                      {formatMessage(lwaSunsetMessages.continueWithLwaButton)}
                    </Button>
                    <Button
                      variant="primary"
                      data-testid="sign-in-builder-id"
                      onClick={(e) => {
                        e.preventDefault();
                        initiateBuilderIDAuth();
                      }}
                    >
                      {formatMessage(
                        lwaSunsetMessages.signInWithBuilderIDButton
                      )}
                    </Button>
                  </SpaceBetween>
                </>
              }
            >
              <TextContent>
                {formatMessage(
                  lwaSunsetMessages.suggestBuilderIDSignInContent,
                  {
                    date: formatDate(config.lwaDeprecationDate, {
                      dateStyle: 'long',
                    }),
                    br: <br />,
                    b: (str) => <b>{str}</b>,
                  }
                )}
              </TextContent>
            </Form>
          </Container>
        </ContainerLayout>
      </Main>
      <Footer enableCookieComponent={enableCookieComponent} />
    </>
  );
};

export default LwaWarningComponent;
