import ExpandableSection from '@amzn/awsui-components-react/polaris/expandable-section';
import TextContent from '@amzn/awsui-components-react/polaris/text-content';
import { useIntl } from 'react-intl';

import styles from './HelpTopics.module.css';
import { AppConfig } from '../types/app';

const HelpTopicExpandable = ({
  question,
  answer,
  config,
}: {
  question: Record<string, any>;
  answer: Record<string, any>;
  config: AppConfig;
}) => {
  const { formatMessage, formatDate } = useIntl();

  return (
    <li className={styles.item}>
      <ExpandableSection
        header={
          <TextContent>
            <h3 className={styles.itemHeader} data-testid="help-topic">
              {formatMessage(question)}
            </h3>
          </TextContent>
        }
      >
        <TextContent className={styles.content}>
          {formatMessage(answer, {
            // These makes sure our help content can have some formatted content.
            //Lwa Sunset date us December 4th, 2023
            endDate: formatDate(config.lwaSunsetDate, {
              dateStyle: 'long',
            }),
            ol: (str) => <ol>{str}</ol>,
            ul: (str) => <ul>{str}</ul>,
            li: (str) => <li>{str}</li>,
            h4: (str) => <h4>{str}</h4>,
            b: (str) => <b>{str}</b>,
            p: (str) => <p>{str}</p>,
            i: (str) => <i>{str}</i>,
            hyperlink: (str) => (
              <a href={str.toString()} target="_blank" rel="noreferrer">
                {str}
              </a>
            ),
            supportLink: (str) => (
              <a
                href="https://support.aws.amazon.com/#/contacts/aws-training"
                target="_blank"
                rel="noreferrer"
              >
                {str}
              </a>
            ),
            table: (str) => (
              <table className={styles.tableBorders}>{str}</table>
            ),
            tr: (str) => <tr>{str}</tr>,
            th: (str) => <th className={styles.tableBorders}>{str}</th>,
            td: (str) => <td className={styles.tableBorders}> {str} </td>,
            builderID: (str) => (
              <a
                href="https://docs.aws.amazon.com/signin/latest/userguide/sign-in-aws_builder_id.html?icmpid=docs_builder_id_console"
                target="_blank"
                rel="noreferrer"
              >
                {str}
              </a>
            ),
            awsBuilderIDAwsCred: (str) => (
              <a
                href="https://docs.aws.amazon.com/signin/latest/userguide/differences-aws_builder_id.html"
                target="_blank"
                rel="noreferrer"
              >
                {str}
              </a>
            ),
            amazonRetail: (str) => (
              <a href="http://amazon.com/" target="_blank" rel="noreferrer">
                {str}
              </a>
            ),
            apn: (str) => (
              <a
                href="https://partnercentral.awspartner.com/APNSelfRegister"
                target="_blank"
                rel="noreferrer"
              >
                {str}
              </a>
            ),
            builderIDSignInIssue: (str) => (
              <a
                href="https://docs.aws.amazon.com/signin/latest/userguide/troubleshooting-sign-in-issues.html"
                target="_blank"
                rel="noreferrer"
              >
                {str}
              </a>
            ),
          })}
        </TextContent>
      </ExpandableSection>
    </li>
  );
};

const HelpTopics = ({
  helpTopics,
  config,
}: {
  helpTopics: { question: Record<string, any>; answer: Record<string, any> }[];
  config: AppConfig;
}) => {
  return (
    <ul className={styles.list}>
      {helpTopics.map(({ question, answer }, i) => (
        <HelpTopicExpandable
          key={i}
          question={question}
          answer={answer}
          config={config}
        />
      ))}
    </ul>
  );
};

export default HelpTopics;
