import { useIntl } from 'react-intl';
import { AppConfig } from '../types/app';
import { SyntheticEvent, useEffect, useState } from 'react';
import accountlinkConsent from '../i18n/accountlinkConsent.messages';
import {
  Form,
  SpaceBetween,
  Button,
  TextContent,
  Box,
  Container,
  Header,
  Checkbox,
} from '@amzn/awsui-components-react';
import { AppURL } from '../constants/urls';
import { accountlinkfaqPageDetails } from '../i18n/accountLinkFaqContent.messages';
import { ExclusivityType } from '../types/auth';
import jwtDecode from 'jwt-decode';
import { AuthToken } from '../utils/authToken';

import {
  GandalfLoginAssociationClient,
  LinkStatus,
  PutAccountLinkStatusWithTokenJustificationCommand,
} from '@amzn/gandalfloginassociation-client';
import AccountLinkFailed from '../pages/AccountLinkFailed';
import usePageTitle from '../hooks/usePageTitle';
import { emitAccountLinkingRUMEvents } from '../services/emitAccountLinkingRUMEvent';
import styles from './CardContainer.module.css';

const CantValidateAccount = ({
  config,
  onDismiss,
  authToken,
}: {
  config: AppConfig;
  onDismiss: () => void;
  authToken: AuthToken;
}) => {
  const { formatMessage } = useIntl();
  usePageTitle(
    formatMessage(accountlinkConsent.areYouSureYouWantSkipLinkingPageTitle)
  );
  const [consentChecked, setConsentChecked] = useState<boolean>(false);

  const originalAuthTokenDecoded: any = jwtDecode(authToken?.jwtToken ?? '');
  const originalAuthAssociateTo = JSON.parse(
    originalAuthTokenDecoded['associate_to']
  );

  useEffect(() => {
    if (authToken) {
      emitAccountLinkingRUMEvents(
        authToken.jwtToken,
        'i_cant_confirm_existing_profile_screen'
      );
    }
    // eslint-disable-next-line
  }, []);

  function handleGoBackToLoginOptions() {
    if (authToken) {
      emitAccountLinkingRUMEvents(
        authToken.jwtToken,
        'i_cant_confirm_existing_profile_go_back_button'
      );
    }

    onDismiss();
  }

  async function handleFormSubmit(event: SyntheticEvent): Promise<void> {
    event.preventDefault();
    if (authToken) {
      emitAccountLinkingRUMEvents(
        authToken.jwtToken,
        'i_cant_confirm_existing_profile_continue_button'
      );
    }
    if (consentChecked && authToken) {
      emitAccountLinkingRUMEvents(
        authToken.jwtToken,
        'i_cant_confirm_consent_checked'
      );
    }
    try {
      const linkingClient = new GandalfLoginAssociationClient({
        endpoint: config.accountLinkingEndpoint,
        region: config.region,
      });
      linkingClient.middlewareStack.removeByTag('SIGNATURE');
      await linkingClient
        .send(
          new PutAccountLinkStatusWithTokenJustificationCommand({
            gandalfToken: authToken?.jwtToken,
            linkStatus: LinkStatus.NOT_LINKABLE,
          })
        )
        .then(() => {
          window.open(
            `${window.location.protocol}//${window.location.host}/login?${authToken?.originalRequestURL}`,
            '_self',
            'noopener,noreferrer'
          );
        });
    } catch (err) {
      <AccountLinkFailed authToken={authToken?.jwtToken} />;
    }
  }

  return (
    <>
      <Container
        header={
          <>
            <Header
              className={styles.header}
              variant="h2"
              headingTagOverride="h1"
            >
              {formatMessage(
                accountlinkConsent.areYouSureYouWantSkipLinkingHeader
              )}
            </Header>
          </>
        }
      >
        <form onSubmit={handleFormSubmit}>
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  formAction="none"
                  onClick={(event) => {
                    event?.preventDefault();
                    handleGoBackToLoginOptions();
                  }}
                >
                  {formatMessage(accountlinkConsent.goBackToLoginOptionsButton)}
                </Button>
                <Button
                  variant="primary"
                  formAction="submit"
                  data-testid="email-submit"
                  disabled={!consentChecked}
                >
                  {formatMessage(
                    accountlinkConsent.yesContinueWithNewProfileButton
                  )}
                </Button>
              </SpaceBetween>
            }
          >
            <SpaceBetween direction="vertical" size="s">
              <TextContent>
                {originalAuthTokenDecoded.Exclusivity ===
                ExclusivityType.EXCLUSIVE
                  ? formatMessage(
                      accountlinkConsent.skipAccountTransferMessage,
                      {
                        b: (str) => <b>{str}</b>,
                        supportLink: (str) => (
                          <a
                            href="https://support.aws.amazon.com/#/contacts/aws-training"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {str}
                          </a>
                        ),
                      }
                    )
                  : formatMessage(
                      accountlinkConsent.skipAccountLinkingMessage,
                      {
                        b: (str) => <b>{str}</b>,
                        supportLink: (str) => (
                          <a
                            href="https://support.aws.amazon.com/#/contacts/aws-training"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {str}
                          </a>
                        ),
                      }
                    )}
              </TextContent>
              <TextContent>
                <Box margin={{ top: 's' }} float="left">
                  <a
                    href={`${AppURL.AccountLinkFAQ}`}
                    target="_blank"
                    data-testid="account-link-help-link"
                    rel="noopener noreferrer"
                  >
                    {formatMessage(
                      accountlinkfaqPageDetails.learnMoreAboutProfileLinking
                    )}
                  </a>
                </Box>
              </TextContent>
              {
                <TextContent>
                  <Checkbox
                    onChange={({ detail }) => setConsentChecked(detail.checked)}
                    checked={consentChecked}
                    data-testid="iunderstand-checkbox"
                  >
                    {' '}
                    {originalAuthAssociateTo.Exclusivity ===
                    ExclusivityType.EXCLUSIVE
                      ? formatMessage(
                          accountlinkConsent.icantLinkConsentTextExclusive,
                          {
                            b: (str) => <b>{str}</b>,
                          }
                        )
                      : formatMessage(
                          accountlinkConsent.icantLinkConsentTextInclusive,
                          {
                            b: (str) => <b>{str}</b>,
                          }
                        )}{' '}
                  </Checkbox>
                </TextContent>
              }
            </SpaceBetween>
          </Form>
        </form>
      </Container>
    </>
  );
};
export default CantValidateAccount;
