import './bootstrap';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import configService from './services/config';
import metricsService from './services/metrics';
import logger from './utils/logger';
import { captureTimingMetrics } from './utils/performance';
import ErrorBoundary from './components/ErrorBoundary';
import AppInitFail from './pages/AppInitFail';
import App from './App';
import Error from './pages/Error';
import { IMPRESSIONS } from './constants/metrics';
import LocaleContextProvider from './contexts/LocaleContextProvider';
import {
  StateStorageContextProvider,
  TransformKeyStorage,
} from './contexts/StateStorageContextProvider';
import { CookieStorage } from './utils/cookie-storage';
import { NonceGeneratorContextProvider } from './contexts/NonceGeneratorContextProvider';
import { CRYPTO_NONCE_GENERATOR } from './utils/crypto-nonce-generator';
import { AppURL } from './constants/urls';
import { STATE_STORAGE_KEY_PREFIX } from './constants/storage';
import {
  isBrowserSupported,
  renderUnupportedBrowser,
} from './utils/browserSupport';
import { rumService } from './services/rum';
import CookieConsent from './components/CookieConsent';
import getPageStyling from './utils/pageStyle';

const $root = document.getElementById('root');

try {
  if (!isBrowserSupported()) renderUnupportedBrowser($root!);
  else renderApp($root!);
} catch (error) {
  renderInitFailed($root!, error);
}

/**
 * Render the app.
 */
async function renderApp(element: HTMLElement) {
  const config = await configService.init();
  metricsService.init(config);
  metricsService.setupUncaughtHandlers();
  captureTimingMetrics();
  rumService.init(config);
  const publisher = metricsService.getPublisher('Index');
  publisher.publishCounterMonitor(IMPRESSIONS, 1);
  publisher.publishStringTruncate('UserAgent', navigator.userAgent);

  var pageStyling = getPageStyling(config);

  ReactDOM.render(
    <div className={pageStyling}>
      <StrictMode>
        <LocaleContextProvider>
          <StateStorageContextProvider
            value={
              new TransformKeyStorage(
                new CookieStorage({
                  path: AppURL.LoginResponse,
                  expires: 1,
                }),
                (key) => `${STATE_STORAGE_KEY_PREFIX}${key}`
              )
            }
          >
            <NonceGeneratorContextProvider value={CRYPTO_NONCE_GENERATOR}>
              <ErrorBoundary FallbackComponent={Error}>
                <App config={config} />
              </ErrorBoundary>
            </NonceGeneratorContextProvider>
          </StateStorageContextProvider>
          <CookieConsent config={config} />
        </LocaleContextProvider>
      </StrictMode>
    </div>,
    element
  );
}

/**
 * Render error page in case the app failed to initialize.
 */
async function renderInitFailed(element: HTMLElement, error: unknown) {
  const config = await configService.init();
  logger.error(error);
  try {
    const publisher = metricsService.getPublisher('InitializeError');
    publisher.publishCounterMonitor(IMPRESSIONS, 1);
    publisher.publishStringTruncate(
      'Message',
      (error as Error).toString() || 'NA'
    );
    publisher.publishStringTruncate('UserAgent', navigator.userAgent);
    const urlParams = new URLSearchParams(window.location.search);
    publisher.publishStringTruncate(
      `ClientId`,
      urlParams.get('client_id') || 'NA'
    );
  } catch (_) {
    // We tried
  }

  ReactDOM.render(
    <LocaleContextProvider>
      <AppInitFail config={config} />
    </LocaleContextProvider>,
    element
  );
}
