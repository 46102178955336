import { Button } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { AuthToken } from '../types/auth';
import { IdPs } from '../constants/providers';
import { useHistory } from 'react-router';
import { AppURL } from '../constants/urls';
import { emitAccountLinkingRUMEvents } from '../services/emitAccountLinkingRUMEvent';
import { localStorageAdapter } from '../services/storage';
import {
  IDP_URL_PARAM,
  LAST_USED_GANDALF_TOKEN,
  LAST_USED_STATE,
  LAST_USED_TOKEN,
  REDIRECT_URI,
} from '../constants/auth';
import { isDevelopment } from '../utils/env';
import { AppConfig } from '../types/app';
import { getAmplifyConfig } from './postAuth/ValidateAccountLink';
import Auth from '@aws-amplify/auth';
import { useStateStorage } from '../contexts/StateStorageContextProvider';
import { FederatedSignInOptions } from '@aws-amplify/auth/lib-esm/types';
import logger from '../utils/logger';
import { marshalAuthToken } from '../utils/authToken';

export function replaceUrlWithReloadSafeParams(searchParams: string) {
  const refreshParams = new URLSearchParams(searchParams);
  return `${AppURL.Login}?${refreshParams.toString()}`;
}

let redirect_uri: URL;
if (isDevelopment) {
  redirect_uri = new URL(`http://${window.location.host}/accountlinkvalidate`);
} else {
  redirect_uri = new URL(`https://${window.location.host}/accountlinkvalidate`);
}

export const AccountLinkSubmitForm = ({
  buttonLabel,
  authToken,
  nonce,
  provider,
  associateIdpTo,
  config,
  lwaCheckRequired = false,
  setRenderAlert,
}: {
  buttonLabel: string;
  authToken: AuthToken;
  nonce: string;
  provider: string;
  associateIdpTo: string;
  config: AppConfig;
  lwaCheckRequired: boolean;
  setRenderAlert: (flag: boolean) => void;
}): JSX.Element => {
  const history = useHistory();

  const stateStorage = useStateStorage();

  const [linkingSpinner, setLinkingSpinner] = useState<boolean>(false);

  useEffect(() => {
    window.onpageshow = function (event) {
      if (event.persisted) {
        setLinkingSpinner(false);
        history.push(replaceUrlWithReloadSafeParams(window.location.search));
      }
    };
    // eslint-disable-next-line
  }, []);

  const initiateSignOutToSignInFlow = (authToken: AuthToken): void => {
    const urlParams = new URLSearchParams(window.location.search);
    history.push({
      pathname: AppURL.SignOutToAllowSignIn,
      search: urlParams.toString(),
      state: {
        authToken: authToken,
      },
    });
  };

  const onClickValidate = () => {
    if (associateIdpTo === '') {
      setRenderAlert(true);
      return;
    }
    if (
      lwaCheckRequired &&
      provider === IdPs.LoginWithAmazon &&
      associateIdpTo === IdPs.LoginWithAmazon
    ) {
      return initiateSignOutToSignInFlow(authToken);
    }
    var urlParams = new URLSearchParams(window.location.search);
    urlParams.set('identity_provider', associateIdpTo);
    replaceUrlWithReloadSafeParams(urlParams.toString());
    emitAccountLinkingRUMEvents(
      authToken.jwtToken,
      'confirm_existing_profile_confirm_button'
    );
    setLinkingSpinner(true);

    const marshaledAuthToken = marshalAuthToken(authToken);

    localStorageAdapter.setItem(LAST_USED_TOKEN, marshaledAuthToken);
    localStorageAdapter.setItem(LAST_USED_GANDALF_TOKEN, marshaledAuthToken);
    localStorageAdapter.setItem(LAST_USED_STATE, nonce);

    urlParams = new URLSearchParams();
    urlParams.set(IDP_URL_PARAM, associateIdpTo ?? '');
    urlParams.set(REDIRECT_URI, redirect_uri.toString());
    urlParams.set('client_id', config.authClientId);
    urlParams.set('response_type', 'code');
    urlParams.set('state', nonce);

    const baseUrl = `https://${config.gandalfDomain}/oauth2/authorize`;
    const IdpURl = `${baseUrl}?${urlParams.toString()}`;

    //Configure Auth
    const amplifyConfig = getAmplifyConfig(config);
    Auth.configure(amplifyConfig.Auth);
    stateStorage.setItem(nonce, IdpURl);
    //Trying Federated SignIn with Amplify
    Auth.federatedSignIn({
      provider: associateIdpTo,
      customState: nonce,
    } as FederatedSignInOptions).catch((error: Error) => {
      logger.debug('Failed to do federatedSignIn.', error);
    });
  };

  return (
    <>
      <Button
        onClick={(e) => {
          e.preventDefault();
          onClickValidate();
        }}
        variant="primary"
        loading={linkingSpinner}
        data-testid="validate-idp"
        id="validate-idp"
      >
        {buttonLabel}
      </Button>
    </>
  );
};
