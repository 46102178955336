import { useEffect } from 'react';
import { PostAuthComponentParams } from '../../types/auth';
import { request } from '../../utils/apiHelper';
import { GANDALF_SESSION } from '../../constants/metrics';
import { isStandardProvider } from '../../constants/providers';

export const GandalfSession = ({
  user,
  onContinue,
  onCancel,
  config,
  setError,
}: PostAuthComponentParams) => {
  useEffect(() => {
    if (
      config.enableGandalfSession !== 'true' ||
      !config.gandalfSessionEndpoint ||
      !user.jwtToken ||
      isStandardProvider(user.providerName)
    ) {
      onContinue();
      return;
    }

    request(
      config.gandalfSessionEndpoint,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        credentials: 'include',
        body: new URLSearchParams({
          token: user.jwtToken,
        }),
      },
      {
        metricNs: GANDALF_SESSION,
      }
    )
      .then((r) => {
        onContinue();
      })
      .catch((e) => {
        setError(e);
      });
  }, [
    config.enableGandalfSession,
    config.gandalfSessionEndpoint,
    user.jwtToken,
    onContinue,
    setError,
    user.providerName,
  ]);

  return <></>;
};
